const space = {
  space: [
    0,
    '0.25rem', // 4 -1
    '0.5rem', // 8 -2
    '1rem', // 16 -3
    '2rem', // 32 -4
    '4rem', // 64 -5
    '8rem', // 128 -6
    '16rem', // 256 -7
    '32rem', // 512 -8
    '1px'
  ]
}

export default space
