const links = {
  links: {
    logoText: {
      textDecoration: 'none',
      fontWeight: 'bold',
      color: 'secondary',
      cursor: 'pointer',
      display: ['inline', 'inline', 'none']
    }
  }
}

export default links
